















































import { Component, Vue } from 'vue-property-decorator'
import { SGM } from '@/api'

@Component({
  components: {
    TransferCharacterModal: () => import('@/components/TransferCharacterModal.vue'),
    TransferBackCharacterModal: () => import('@/components/TransferBackCharacterModal.vue')
  }
})
export default class CharacterTransfer extends Vue {
  transferableCharacters = []
  tableColumns = [
    { key: 'accountName' },
    { key: 'currentAccountId', },
    { key: 'originalAccountId' },
    { key: 'actions' }
  ]
  tableKey = 0

  async mounted() {
    await this.fetchTransferableCharacters()

    this.$root.$on('refreshCharacterTransferTable', async () => {
      await this.fetchTransferableCharacters()
    })
  }

  async fetchTransferableCharacters() {
    try {
      this.transferableCharacters = await SGM.getTransferableCharacters();

      (this.$refs.characterTransferTable as any).refresh()
      this.tableKey += 1
    } catch(e) {
      console.error(e)
    }
  }
}
